import React from 'react'
import styled from 'styled-components/macro'
import {Link} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectIsAuth } from '../../redux/slices/auth'
import { useTranslation } from 'react-i18next'
import avatarUrl from './../UserLK/avatar.png'
import heart from './../../assets/like.png'
import pencil from './../../assets/pencil.png'



const Section = styled.section`
width: 322px;
margin-right: 55px;
@media (max-width: 577px){
    margin: 25px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
`
const ImgCont = styled.div`
    margin: 0 6.2rem;
    display: flex;
    justify-content: center;
img{
    width: 114px;
    height: 114px;
    border-radius: 50%;
    object-fit: cover;
}
`
const Text = styled.h3`
width: 222px;
font-size: 22px;
font-weight: 400;
text-align: center;
margin: 0 auto;
@media (max-width: 577px){
    margin: 0 auto;
    text-align: center;
    max-width: 262px;
}

`
const Menu = styled.div`
background: #F5F5F5;
border: 1px solid #F5F5F5;
border-radius: 25px;
width: 290px;
height: 97px;
margin: 20px auto;

@media (max-width: 577px){
    margin-top: 10px;
    max-width: 262px;
}
`
const MenuCont = styled.div`
    margin: 0 0 0 18px;

    @media (max-width: 577px){
        margin: 0 9px 0 9px;
    }
`

const MenuItem = styled.h3`
height: 35px;
width: fit-content;
font-size: 17px;
font-weight: 400;
margin: 0.8rem 0;
width: 100%;

&:first-child{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

img{
    width: 16px;
    height: 16px;
    padding: 0 0.3rem;
}
`



const ProfileMenu = () => {

    const isAuth = useSelector(selectIsAuth)
    const { t } = useTranslation()

  return (
    <Section>
        <ImgCont>
            <Link to="/account">
                {isAuth.avatarUrl != undefined || isAuth.avatarUrl != null || isAuth.avatarUrl != '' ? <img src={`/api/${isAuth.avatarUrl}`} alt='profile' />
                :
                <img src={avatarUrl} alt='profile'/>}
                </Link>
        </ImgCont>
        <Text>
            {isAuth ? <span>{`${isAuth.username}`} {`${isAuth.surname}`}</span> : <></>}
        </Text>
        <Menu>
            <MenuCont>
            <MenuItem><Link to="Update"><img src={pencil} alt="pen" />{t('redact')}</Link></MenuItem>
            <MenuItem><Link to="Favorite"><img src={heart} alt="heart" />{t('favorite')}</Link></MenuItem>
            </MenuCont>
        </Menu>
    </Section>
  )
}

export default ProfileMenu
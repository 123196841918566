import React, { forwardRef, useState } from 'react'
import styled from 'styled-components/macro'
import axios from '../../../utils/axios'
import { fetchUpdateMe, selectIsAuth } from '../../../redux/slices/auth'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import lockIcon from './../../../assets/lock-icon.svg'
import { OLD_KEYS_COUNTRY_MAP } from '../../../const/old-keys-country-map/old-keys-country-map'
import countriesLanguages from '@hotosm/iso-countries-languages';

const Head = styled.div`
font-weight: 400;
font-size: 26px;
padding-bottom: 10px;
border-bottom: 1px solid rgba(148, 148, 148, 0.5);
font-family: 'Palatino';

@media (max-width: 577px){
  width: 90%;
  margin-left: 1.3rem;
}
`
const Block = styled.div`
margin-top: 2rem;

@media (max-width: 577px){
  width: 90%;
  margin-left: 1.3rem;
  border: none;
}
`

const FormBlock = styled.div`
position: relative;
padding-bottom: 1rem;
padding-top: 0rem;
input{
    margin-top: 1rem;
    width: 350px;
    height: 35px;
    border: 1px solid #f1f1f1;
    background: #f1f1f1;
    border-radius: 5px;
    outline: none;
    text-indent: 10px;
}

@media (max-width: 577px){
  margin-top: 2rem;
  input {
    width: 300px;
  }
}
`
const Name = styled.h4`
font-size: 18px;
font-weight: 400;
`

const Avatar = styled.div`
justify-content: center;
align-items: center;
position: absolute;
margin-left: 32rem;
cursor: pointer;
display: flex;
flex-direction: column;
align-items: center;

span{
    position: relative;
    top: 1rem;
}
img{
  border-radius: 50%;
  width: 110px;
  height: 110px;
  position: relative;
  margin-left: 3rem;
}

@media (max-width: 577px){
  position: relative;
  margin: 0 0;
  span{
    margin-left: 7.2rem;
  }
}
`

const Round = styled.div`
width: 110px;
height: 110px;
border-radius: 50%;
border: 1px solid rgba(171, 154, 144, 0.7);
margin: 0 auto;
`

const Save = styled.button`
width: 150px;
height: 40px;
background: rgba(254, 97, 3, 0.5);
border: 1px solid rgba(0, 0, 0, 0);
border-radius: 15px;
font-family: 'Montserrat';
font-size: 17px;
top: 8rem;
right: -1.3rem;
position: absolute;
opacity: 0.5;
z-index: 12;
transition: 0.3s ease;

&:hover{
  opacity: 1;
}
`

const Btn = styled.button`
position: relative;
width: 120px;
height: 30px;
background-color: #f1f1f1;
color:  #000;
outline: none;
border: 0px solid #808080;
font-family: Montserrat;
font-weight: 400;
font-size: 12px;
border-radius: 10px;
cursor: pointer;
left: 22rem;
top: 0rem;

`

const SocialsHead = styled.div`
font-weight: 400;
font-size: 20px;

margin-top: 5rem;
`
const SocialsBlock = styled.div`
border-bottom: 1px solid rgba(148, 148, 148, 0.5);
height: auto;
margin-top: 2rem;

@media (max-width: 577px){
  margin-top: -2rem;
}
`

const BtnSave = styled.button`
position: relative;
width: 210px;
height: 40px;
background-color: rgba(254, 97, 3, 0.2);
color:  #000;
outline: none;
border: 0px solid #808080;
font-family: Montserrat;
font-weight: 500;
font-size: 16px;
border-radius: 10px;
cursor: pointer;
margin-left: 0rem;
margin-top: 2rem;
`

const ButtonAvatar = styled.button`
position: relative;
width: 227px;
height: 40px;
background-color: rgba(254, 97, 3, 0.2);
color:  #000;
outline: none;
border: 0px solid #808080;
font-family: Montserrat;
font-weight: 500;
font-size: 16px;
border-radius: 10px;
cursor: pointer;
margin-top: 20px;
padding: 0 10px;
box-sizing: border-box;
`

const Country = styled.div`
  margin: 10px 0;
  display: flex;

  select{
      margin-top: 10px;
      font-family: 'Montserrat';
      width: 277px;
      height: 35px;
      border: 1px solid #949494;
      border-radius: 5px;
      outline: none;
      font-size: 17px;
  }
`
const CountryBlock = styled.div`
  @media (max-width: 577px){
  .country{
      margin-left: 0rem;
    }
  }
`

const PasswordBlock = styled.div`
    padding-top: 10px;
    display: flex;
    flex-direction: column;
`

const PasswordLable = styled.label`
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 400;
  line-height: 42px;
`

const InputWrapper = styled.div`
  position: relative;
  width: 350px;
  input{
    display: block;
    width: 350px;
    height: 35px;
    border: 1px solid #949494;
    border-radius: 5px;
    outline: none;
    text-indent: 10px;
    box-sizing: border-box;
  }

  img {
    display: block;
    position: absolute;
    right: 15px;
    bottom: 20%;
    cursor: pointer;
  }

  @media (max-width: 577px){
      width: 100%;
      input {
        width: 100%;
      }
    }
`

const PasswordTitle = styled.div`
    color: #000;
    font-family: Montserrat;
    font-size: 19px;
    font-weight: 500;
`


const Form = forwardRef(({title = " ", ...formProps}, ref) => {
  return(
      <FormBlock>
          <Name>{title}</Name>
          <input ref={ref} type="text" {...formProps}/>
      </FormBlock>
  )
})


const FirstInfo = () => {

    const [avatarUrl, setAvatarUrl] = React.useState(null)
    const [isShown, setIsShown] = React.useState(false)
    const inputFileRefAvatar = React.useRef('')
    const [isLoading, setLoading] = React.useState('')
    const { t, i18n  } = useTranslation()
    const authInfo = useSelector(selectIsAuth)
    const [oldPass, setOldPass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [isShowOldPass, setIsShowOldPass] = useState(false);
    const [isShowNewPass, setIsShowNewPass] = useState(false);

    const dispatch = useDispatch()
    const countriesList = countriesLanguages.getCountries(i18n.language);

    const { register, handleSubmit } = useForm({
        defaultValues: {
          surname: authInfo.surname,
          username: authInfo.username,
          email: authInfo.email,
          country: OLD_KEYS_COUNTRY_MAP[authInfo.country] || authInfo.country,
          fbUrl: authInfo.fbUrl,
          tgUrl: authInfo.tgUrl,
          instUrl: authInfo.instUrl,
          vkUrl: authInfo.vkUrl
        },
        mode: 'onChange'
      })

    const onSubmit = async (values) =>{
        const data = await dispatch(fetchUpdateMe(values))
    
        if (!data.payload) {
          return alert('Не удалось авторизоваться')
        }

        if (oldPass && newPass) {
          await sendPass();
        }
    
        window.location.reload();
    }  
  
    const handleChangeAvatar = async (event) => {
      try {
        const formData = new FormData();
        const files = event.target.files[0]
        formData.append('avatar', files);
        const { data } = await axios.post('/upload/avatar', formData)
        setAvatarUrl(data.url)
        setIsShown(!isShown)
        console.log(avatarUrl)
    } catch (err) {
        console.warn(err);
        alert('Ошибка при загрузке изображения')
      }
    }

    const onSubmitAvatar  = async () => {
        try {
            setLoading(true)
          
            const fields ={
                avatarUrl,
            }
    
            const { data } = await axios.patch('/auth/me', fields)
    
            const id = data._id
    
            console.log(id)
            window.location.reload()
        } catch (err) {
            console.warn(err)
            alert('Ошибка при загрузке товара')
        }
    }

    const sendPass = async () => {
      await axios.patch('/auth/me/password ', {oldPassword: oldPass, newPassword: newPass}).then(() => {
        alert(t('passWasChanged'))
      })
      .catch((data) => {
        const res = data.response.data.message;
        alert(res);
      })
    }

  return (
    <>
    <Head>
        {t('redact')}
    </Head>
    <Block>
    <input ref={inputFileRefAvatar} name='avatar' type='file' onChange={handleChangeAvatar} encType="multipart/form-data"  hidden/>
        {avatarUrl === null ? 
          <Avatar onClick={() => inputFileRefAvatar.current.click()}>        
            <Round />
            <ButtonAvatar> {t('avatar')}</ButtonAvatar>
          </Avatar> 
          :
          <Avatar>
          <img className='image2' src={`/api/${avatarUrl}`} alt='uploaded' />
          {isShown && <Save onClick={onSubmitAvatar}>Сохранить</Save>}
          </Avatar>
        }
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormBlock>
        <Name>{t('name')}</Name>
        <input type="text" {...register('username', { required: 'Укажите имя'})} />
      </FormBlock>
      <FormBlock>
        <Name>{t('surname')}</Name>
        <input type="text" {...register('surname', { required: 'Укажите фамилию'})} />
      </FormBlock>
        <Form title="Email" {...register('email')} />
        <PasswordBlock>
            <PasswordTitle>
              {t('changePass')}
            </PasswordTitle>
            <PasswordLable>
              {t('prevPass')}
              <InputWrapper>
                  <input type={isShowOldPass ? "text" : "password"}  value={oldPass} onChange={(e) => {setOldPass(e.currentTarget.value)}}/>
                  <img alt='lock' onClick={() => setIsShowOldPass(!isShowOldPass)} src={lockIcon} />
              </InputWrapper>
            </PasswordLable>
            <PasswordLable>
              {t('newPass')}
              <InputWrapper>
                  <input minLength={6} type={isShowNewPass ? "text" : "password"} value={newPass} onChange={(e) => setNewPass(e.currentTarget.value)}/>
                  <img alt='lock' onClick={() => setIsShowNewPass(!isShowNewPass)} src={lockIcon}  />
              </InputWrapper>
            </PasswordLable>
        </PasswordBlock>
        <Country>
          <CountryBlock>
            <Name className='country' {...register('country')}>
              {t('yourcountry')}
            </Name>
            <select {...register('country')}>
              {Object.entries(countriesList).map(([k, v]) => (
                <option value={k} key={k}>{k === 'BY' && i18n.language === 'ru' ? 'Беларусь' : v}</option>
              ))}
            </select>
          </CountryBlock>
        </Country>
    <BtnSave type="submit">
        <span>{t('save')}</span>
    </BtnSave>
    </form>
    </Block>
    </>
  )
}

export default FirstInfo
import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import axios from '../../../utils/axios'
import CardPreview from '../../Card/card-preview'

import defaultToy from './../../../assets/defaultToy.png'
import defaultAvatar from './../../../assets/cardPhoto.png'
import { selectIsAuth } from '../../../redux/slices/auth'
import { useSelector } from 'react-redux'
import LoaderCircle from '../../../ui-kit/loader-circle/loader-circle'

const Card = styled.section`
    width: 900px;
    margin: 2rem 0rem;
    display: grid;
    grid-template-columns: ${props => props.loading ? '1fr' : '1fr 1fr 1fr'}; 
    grid-gap: 20px;
    justify-content: center;

    @media (max-width: 577px){
        width: 100%;
        display: grid;
        grid-template-columns: ${props => props.loading ? '1fr' : '1fr 1fr'}; ;
        gap: 50px 10px;
        justify-content: flex-start;
        padding: 0 5px;
        box-sizing: border-box;
    }
`

const CardBlock = (props) => {
    const [cards, setCards] = useState([])
    const isAuth = useSelector(selectIsAuth)
    const [loading, setLoading] = useState(false);


    const fetchMyCards = async () => {
        setLoading(true)
        try {
            const { data } = await axios.get('/market/user/me/liked')
            setCards(data)
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchMyCards()
    }, [])

  return (
    <Card loading={loading}>
        { 
            loading ? 
                <LoaderCircle/> 
                : 
            cards?.reverse().filter((card) => !!card).map((card, index) => (
                <CardPreview
                    link={`/card/${card._id}`}
                    key={card._id}
                    altWork={card.name} 
                    imageWork={card.imgUrl ?`/api/${card.imgUrl[0]}` : defaultToy}
                    avatar={`/api/${card.author.avatarUrl}` || defaultAvatar}
                    shopName={card.author.shopname || `${card.author.username} ${card.author.surname}`}
                    nameWork={card.name}
                    price={card.price + ' ' + card.currency}
                    size={card.size + 'cm'}
                    likeActive={card.like.includes(isAuth && isAuth._id)}    
                    status={card.status}  
                />
            ))
        }
    </Card>
  )
}

export default CardBlock
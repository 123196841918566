import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components/macro'
import img from './../../../assets/search.svg'
import axios from '../../../utils/axios'
import debounce from 'lodash.debounce'
import { useTranslation } from 'react-i18next'

const Search = styled.div`
height: fit-content;

input {
  border: none;
  border-radius: 30px;
  margin-top: 1px;
  background: white;
  height: 26px;
  width: 470px;
  outline: none;
  padding-left: 25px;
  font-family: 'Montserrat';
  font-size: 14px;

  @media (max-width: 1180px){
    width: 400px;
  }

  @media (max-width: 1090px){
    width: 240px;
  }
}

.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(0px);
    transition: 0.1s ease;
}

.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: 0.1s ease;
  z-index: 25565;
}
`
const SearchInput = styled.div`
margin-right: 10px;
display: flex;
border: 1px solid #949494;
border-radius: 30px;
padding-bottom: 2px;
background: white;
`

const SearchIcon = styled.div`
height: 20px;
width: 10px;
display: flex;
place-items: center;

img{
    position: relative;
    height: 17px;
    margin-top: 8px;
    margin-left: 0.7rem;
    opacity: 1;
}
`
const UserList = styled.div`
max-height: 235px;
position: absolute;
border-radius: 0 0px 10px 10px;
width: 510px;
background-color: white;
overflow-y: scroll;
padding: 5px 10px 0 5px;
box-sizing: border-box;

.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(0px);
    transition: 0.5s ease;
}

.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: 0.5s ease;
  z-index: 25565;
}

&::-webkit-scrollbar{
  width: 5px;
  background-color: transparent;
}

&::-webkit-scrollbar-track{
  background-color: transparent;
}

&::-webkit-scrollbar-thumb{
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 25px;
}

@media (max-width: 1180px){
    width: 440px;
}

@media (max-width: 1090px){
    width: 350px;
}
`

const User = styled.div`
display: flex;
justify-content: space-between;
border-bottom: 1px solid rgba(0, 0, 0, 0.1);
height: 45px;

img{
  margin-top: 0.3rem;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

span{
  margin-top: 0.7rem;
  margin-left: 0.5rem;
}
`

const UserSubs = styled.div`
display: flex;
align-items: center;

@media (max-width: 1090px){
    display: none;
}
`

const Searchbar = ({placeholder}) => {

  const [users, setUsers] = React.useState([])
  const [searchTerm, setSearchTerm] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState()
  const { t } = useTranslation()

  let searchRef = React.useRef()
 
  const fetchUsers = useCallback(async () => {
      try {
          const { data } = await axios.get(`/search?q=${searchTerm}`)
          setUsers(data.items)
      } catch (error) {
          console.log(error)
      }
  }, [searchTerm])

  const debouncedFetch = useMemo(() => debounce(fetchUsers, 500), [fetchUsers])


  React.useEffect(() => {
    searchTerm.length > 2 && debouncedFetch()
  }, [debouncedFetch, searchTerm])

  React.useEffect(() => {
    const handler = (e) => {
      if (!searchRef.current.contains(e.target)) {
        setOpen(false);
      }
    }

    document.addEventListener('mousedown', handler)
    return () => {
      document.removeEventListener('mousedown', handler)
    }
  });

  React.useEffect(() => {
    return () => {
      debouncedFetch.cancel();
    }
  }, [debouncedFetch]);

  return (
    <Search ref={searchRef} >
        <SearchInput onClick={() => (setOpen(!open))}>
            <SearchIcon><img src={img} alt="srch" /></SearchIcon>
            <input 
            type="text" 
            value={value}
            autoFocus
            onChange={(e) => {setOpen(true); setSearchTerm(e.target.value)}} 
            placeholder={placeholder} />
        </SearchInput>
        <UserList className={`userList ${open && users.length > 0 ? 'active' : 'inactive'}`}>
          {
            users.map((obj, index) => 
              obj.type === "user" ? 
              <a href={`/${obj.shortlink || obj._id}`} key={index}>
                <User>
                  <div style={{display: 'flex'}}>
                    <img src={`/api/${obj.avatarUrl}`} />
                    <span>{obj.username}</span>
                  </div>
                  <span>{obj.shopname}</span>
                  <UserSubs>{t('subscribers')}: {obj.subsCount}</UserSubs>
                </User>
              </a> :
              <a href={`/card/${obj._id}`} key={index}>
              <User>
                <div style={{display: 'flex'}}>
                  <img src={`/api/${obj.imgUrl[0]}`} />
                  <span>{obj.name}</span>
                </div>
                <span>{obj.author.username}</span>
                <UserSubs>{obj.price}{obj.currency}</UserSubs>
              </User>
            </a>
            )
          }
        </UserList>
    </Search>
  )
}

export default Searchbar
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { format } from 'date-fns'
import ButtonMarket from './ButtonMarket'
import { selectIsAuth } from '../../redux/slices/auth'
import { useTranslation } from 'react-i18next'
import defaultImg from './../../assets/defaultToy.png'
import axios from '../../utils/axios'
import LoaderCircle from '../../ui-kit/loader-circle/loader-circle'
import bell from './../../assets/bell.png'

const Section = styled.section`
margin-left: 25px;

@media (max-width: 577px){
    margin: 0;
    padding: 0 20px;
}
`

const Description = styled.section`
position: relative;
border-bottom: 0.5px solid rgba(215, 215, 215, 1);

@media (max-width: 577px){
    margin-left: 0rem;
    display: flex;
}
`

const Head = styled.h2`
    font-family: 'Palatino';
    font-weight: 400;
    line-height: 50px;
`

const HeadWrapper = styled.div`
    display: flex; 
    align-items: center;
`

const BellImg = styled.img`
    width: 16px;
    height: 18px;
    margin-left: 10px;
    margin-top: 5px;
    @media (max-width: 577px){
        width: 14px;
        height: 15px;
    }
`

const ActivityBlock = styled.div`
overflow-y: scroll;
position: relative;
margin-top: 15px;
max-width: 530px;
height: 400px;

@media (max-width: 577px){
    margin-left: 0rem;
    height: 300px;
    max-width: 500px;
}
`

const Page = styled.div`
height: fit-content;
border-bottom: 0.5px solid rgba(215, 215, 215, 1);
padding-top: 5px;
padding-bottom: 5px;
line-height: 25px;

&:last-child{
    border-bottom: 0;
}
`

const PageDesc = styled.div`
font-size: 16px;

@media (max-width: 577px){
    font-size: 14px;
}
`

const PageDate = styled.div`
font-size: 13px;

@media (max-width: 577px){
    font-size: 11px;
}
`

const PageDescImg = styled.div`
    display: flex;
    align-items: center;
    margin-right: 10px;

    img{
        width: 60px;
        border-radius: 15px;
    }

    @media (max-width: 577px){
        img{
            width: 50px;
        }
    }
`

const PageInner = styled.div`
    display: flex;
    align-items: center;
`
const ActivityText = () => {
    const isAuth = useSelector(selectIsAuth)
    const { t } = useTranslation()

    const [activity, setActivity] = useState(null);
    const [loading, setLoading] = useState(false);

    async function fetchActivity() {
        setLoading(true)
        const result = await axios.get('/feed');
        setActivity(result.data);
        setLoading(false)
    };

    useEffect(() => {
        fetchActivity();
    }, [])

  return (
    <Section>
        <Description>
            <HeadWrapper>
                <Head>
                    {t('activity')} 
                </Head>
                <BellImg src={bell} alt='bell' />
            </HeadWrapper>
            <Link to={`/${isAuth.shortlink || isAuth._id}`}><ButtonMarket /></Link>
        </Description>
        {
            loading ? 
                <LoaderCircle/> 
              : 
                <ActivityBlock>
                {activity && activity.events.length ? activity.events.filter(({ entity, type }) =>
                    (type.toLowerCase() === 'card' && activity.cards[entity]) || (type.toLowerCase() === 'user' && activity.users[entity])).map((event) => {
                    switch(event.type.toLowerCase()) {
                        case 'card':
                            switch(event.event) {
                                case 'card_sold':
                                    return (
                                        <Page>
                                            <PageDesc>
                                                {t('workSold')} {activity.cards[event.entity] && activity.cards[event.entity].name}
                                            </PageDesc>
                                            <PageDate>{format(new Date(event.updatedAt), 'dd MMM')}</PageDate>
                                        </Page >
                                    )
                                case 'card_create':
                                    return (
                                        <Page>
                                            <PageInner>
                                                <div>
                                                    <PageDescImg> <img src={activity.cards[event.entity] && (activity.cards[event.entity].imgUrl === null  ? defaultImg : `/api${activity.cards[event.entity].imgUrl[0]}`)} alt='preview' /></PageDescImg>
                                                </div>
                                                <div>
                                                    <PageDesc>{t('addedWork')} {activity.cards[event.entity] && activity.cards[event.entity].name}</PageDesc>
                                                    <PageDate>{format(new Date(event.updatedAt), 'dd MMM')}</PageDate>
                                                </div>
                                            </PageInner>
                                        </Page>
                                    )
                                case 'order_create':
                                    return (
                                        <Page>
                                            <PageDesc>
                                                {t('newOrder')} {activity.cards[event.entity] && activity.cards[event.entity].name}
                                            </PageDesc>
                                            <PageDate>{format(new Date(event.updatedAt), 'dd MMM')}</PageDate>
                                        </Page>
                                    )
                                case 'like':
                                    return isAuth._id !== event.user ? (
                                        <Page>
                                            <PageInner>
                                                <div>
                                                    <PageDescImg> <img src={activity.cards[event.entity] && (activity.cards[event.entity].imgUrl === null  ? defaultImg : `/api${activity.cards[event.entity].imgUrl[0]}`)} alt='preview' /></PageDescImg>
                                                </div>
                                                <div>
                                                    <PageDesc>
                                                        {activity.users[event.user].username} {activity.users[event.user].surname} {t('likedWork')} {activity.cards[event.entity] && activity.cards[event.entity].name}
                                                    </PageDesc>
                                                    <PageDate>{format(new Date(event.updatedAt), 'dd MMM')}</PageDate>
                                                </div>
                                            </PageInner>
                                        </Page>
                                    ) : null
                                default:
                                return null
                            }
                            
                        case 'user':
                            switch(event.event) {
                                case 'subscribe':
                                    return (
                                        <>
                                            {activity.users[event.user].role === "User" ?
                                                <Page>
                                                    <PageDesc>{activity.users[event.user].username} {activity.users[event.user].surname} {t('newSubs')}</PageDesc>
                                                    <PageDate>{format(new Date(event.updatedAt), 'dd MMM')}</PageDate>
                                                </Page>
                                                :
                                                null
                                            }
                                        </>
                                    )
                                default:
                                    return null
                            }
                        default:
                            return null
                    }
                }) :
                    <>{t('activityPlace')}</>
                }
                </ActivityBlock>
        }
    </Section>
  )
}

export default ActivityText
import React from 'react'
import styled from 'styled-components/macro'
import { useSelector } from 'react-redux'
import axios from '../../utils/axios'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { fetchUpdateMe, selectIsAuth } from '../../redux/slices/auth'
import { Navigate } from 'react-router-dom'

const Section = styled.div`
  width: 1155px;
  margin: 0 auto;
  img{
  height: 230px;
  width: 1155px;
  z-index: 1;  
}

`

const BannerCard = styled.div`
height: 100px;
width: 1155px;
margin: 0 auto;
padding-top: 7rem;
text-align: center;
background: #DADADA;
z-index: 0;

`
const Description = styled.section`
height: 250px;
width: 705px;
margin-left: 30rem;
margin-top: 2rem;

textarea{
  margin-top: 1rem;
  font-family: 'Montserrat';
  width: 562px;
  height: 100px;
  border: 1px solid #949494;
  border-radius: 10px;
  outline: none;
  resize: none;
  padding: 5px 5px;
}
`
const Head = styled.h2`
font-family: 'Palatino';
font-weight: 400;
border-bottom: 0.5px solid rgba(215, 215, 215, 1);
line-height: 35px;
`
const Body = styled.p`
margin-top: 1.7rem;
font-family: 'Montserrat';
font-size: 17px;
font-weight: 400;
line-height: 25px;
cursor: pointer;
border-radius: 10px;
border: 1px solid;
width: 200px;
text-align: center;
`

const Buttn = styled.button`
margin-top: 3rem;
width: 235px;
height: 45px;
background: rgba(254, 97, 3, 0.2);
border: 1px solid rgba(0, 0, 0, 0.2);
border-radius: 10px;
font-family: 'Montserrat';
font-size: 17px;
margin: 0.5rem 31.5%;
`

const Avatar = styled.div`
height: 250px;
width: 705px;
margin-left: 30rem;
margin-top: 2rem;
img{
  width: 100px;
  height: 100px;
  margin-top: 2rem;
}
`
const AvatarCard = styled.div`
margin-top: 3rem;
border: 1px solid;
text-align: center;
width: 150px;
border-radius: 10px;
`


const Banner = () => {

  const [bannerUrl, setBannerUrl] = React.useState(null)
  const isAuth = useSelector(selectIsAuth)
  const inputFileRefBanner= React.useRef(null)
  const [avatarUrl, setAvatarUrl] = React.useState(null)
  const [status, setStatus] = React.useState(null)
  const [isLoading, setLoading] = React.useState('')
  const [isShown, setIsShown] = React.useState(false)
  const inputFileRefAvatar = React.useRef(null)


  const toggleFieldset = () => setIsShown(!isShown);


  const handleChangeBanner = async (event) => {

    try {
      const formData = new FormData();
      const files = event.target.files[0]
      formData.append('banner', files);
      const { data } = await axios.post('/upload/banner', formData)
      setBannerUrl(data.url)
      console.log(bannerUrl)
  } catch (err) {
      console.warn(err);
      alert('Ошибка при загрузке изображения')
  }

  }

  const handleChangeAvatar = async (event) => {

    try {
      const formData = new FormData();
      const files = event.target.files[0]
      formData.append('avatar', files);
      const { data } = await axios.post('/upload/avatar', formData)
      setAvatarUrl(data.url)
      console.log(avatarUrl)
  } catch (err) {
      console.warn(err);
      alert('Ошибка при загрузке изображения')
  }

  }

  const onSubmit = async () => {
    try {
        setLoading(true)

        const fields ={
            bannerUrl,
            status,
            avatarUrl,
        }

        const { data } = await axios.patch('/auth/me', fields)

        const id = data._id

        console.log(id)
    } catch (err) {
        console.warn(err)
        alert('Ошибка при загрузке товара')
    }
}

if (window.localStorage.getItem('token') && !isAuth) {
    return <Navigate to='/' /> 
}


  return (
    <>
    <Section>
    <input ref={inputFileRefBanner} name='banner' type='file' onChange={handleChangeBanner} encType="multipart/form-data"  hidden/>
      {bannerUrl === null ? 
      <BannerCard onClick={() => inputFileRefBanner.current.click()}>Нажмите здесь чтобы добавить Баннер</BannerCard> 
      :
      <img className='image0' src={`/api/${bannerUrl}`} alt='uploaded' />
      }
    </Section>
    <Avatar>
      <Head>Добавить фотографию профиля</Head>
      <input ref={inputFileRefAvatar} name='avatar' type='file' onChange={handleChangeAvatar} encType="multipart/form-data"  hidden/>
      {avatarUrl === null ? 
      <AvatarCard onClick={() => inputFileRefAvatar.current.click()}>Нажмите здесь чтобы добавить Аватар</AvatarCard> 
      :
      <img className='image2' src={`/api/${avatarUrl}`} alt='uploaded' />
      }
    </Avatar>
    <Description>
        <Head>Статус</Head>
        <Body onClick={toggleFieldset}>Добавить статус</Body> 
        {isShown &&
            <textarea value={status} onChange={(e) => setStatus(e.target.value)}></textarea>
        }
    </Description>
    <Buttn onClick={onSubmit}>
          Сохранить
    </Buttn>
    </>
  )
}

export default Banner
import React from 'react'
import styled from 'styled-components/macro'
import {Link, useLocation} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectIsAuth } from '../../../redux/slices/auth'
import { useTranslation } from 'react-i18next'
import avatarUrl from './../../UserLK/avatar.png';
import countriesLanguages from '@hotosm/iso-countries-languages'
import { OLD_KEYS_COUNTRY_MAP } from '../../../const/old-keys-country-map/old-keys-country-map'
import pin from './../../../assets/pin.svg'

const Section = styled.div`
width: 230px;
background-color: #fff;
border: 1px solid rgba(171, 154, 144, 0.6);
border-radius: 20px;
justify-content: center;
align-items: center;
z-index: 9999;
height: max-content;

@media (max-width: 577px){
    display: ${props => props.pathname === '/account/AddWork' || 'account/CheckWork' ? 'none' : 'flex'};
    height: auto;
    width: 100%;
    left: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    margin-top: 66px;
}
`
const ImgContainer = styled.div`
position: relative;
margin: 1rem 3.7rem;
z-index: 9999;

@media (max-width: 577px){
    margin: 0;
}

img{
    border-radius: 50%;
    width: 108px;
    height: 108px;
    object-fit: cover;
}
`
const TextBlock = styled.div`
width: 230px;
line-height: 25px;

@media (max-width: 577px){
    div{
    display: flex;
    justify-content: center;
    align-items: center;
    }
}
`
const ShopName = styled.h3`
    position: relative;
    text-align: center;
    margin-top: 1rem;
    font-size: 16px;
    opacity: 0.8;
    word-break: break-word;
    padding: 0 15px;
`

const SellerName = styled.h3`
width: 240px;
text-align: center;
font-size: 14px;
font-weight: 400;
margin-top: 0rem;
`

const ShopLocation = styled.div`
display: flex;
justify-content: center;
padding: 0 15px;

img{
    width: 15px;
    height: 15px;
    display: block;
    margin-top: 5px;
}
h4 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 13px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (max-width: 577px){
    display: flex;
    margin-left: 0rem;
    padding: 0 5px;
    img{
        position: relative;
    }
    h4{
        margin: -4px 0.1rem;

    }
}
`
const Subs = styled.h3`
font-size: 13px;
font-weight: 400;
width: 230px;
text-align: center;

@media (max-width: 577px){
    width: fit-content;
    height: fit-content;
    margin-left: -0.5rem;
    margin-top: 0px;
}
`

const MenuSeller = styled.div`
    list-style: none;

    @media (max-width: 577px){
    display: none;
}`

const ButtonMenu = styled.div`
    width: 175px;
    padding-left: 3.5rem;
    text-align: left;
    line-height: 35px;
    border-top: 0.5px solid rgba(215, 215, 215, 1);
    transition: all 0.3s ease;
    cursor: pointer;

    img{
        width: 18px;
        height: 18px;
        position: relative;
        margin-left: -1.5rem;
        top: 3px;
    }

    &:hover{
        background: #D5C8C8;
    }
    span{
        margin-left: 0.5rem;
    }

    @media (max-width: 577px) {
        border: none;
        width: fit-content;
    }
`
const ButtonMenuBottom = styled.li`
width: 175px;
height: 37px;
padding-left: 3.5rem;
text-align: left;
line-height: 35px;
border-top: 0.5px solid rgba(215, 215, 215, 1);
border-radius: 0 0 20px 20px;
transition: all 0.3s ease;
cursor: pointer;

&:hover{
    background: #D5C8C8;
}
@media (max-width: 577px) {
    border: none;
}
`

const SellerCard = () => {
    const { pathname } = useLocation();
    const isAuth = useSelector(selectIsAuth)
    const { t, i18n } = useTranslation();

    const country = countriesLanguages.getCountry(i18n.language, OLD_KEYS_COUNTRY_MAP[isAuth?.country] || isAuth?.country);
    const translatedCountry = isAuth?.country ? (country || t('russia')) : t('russia')


  return (
    <>
    <Section pathname={pathname}>
        <Link to="/account">
            <ImgContainer>
                <img src={isAuth.avatarUrl ? `/api/${isAuth.avatarUrl}` : avatarUrl} alt="cardimg" />
            </ImgContainer>
        </Link>
        <TextBlock>
            {isAuth ? <ShopName>{`${isAuth.shopname ?? ''}`}</ShopName> : (<ShopName>ShopName</ShopName>)}
            {isAuth ? <SellerName>{`${isAuth.username}`} {`${isAuth.surname}`}</SellerName> : (<SellerName>Name</SellerName>)}
            <div>
            <ShopLocation>
                <img src={pin} alt="location"/>
                <div>{translatedCountry}</div>
            </ShopLocation>
            <Subs>
                {isAuth.subsCount > 0 ? <>{`${isAuth.subsCount}`}</> : 0 } {t('subs')}
            </Subs>
            </div>
            <MenuSeller>
                <Link to="Orders"><ButtonMenu>{t('orders')}</ButtonMenu></Link>
                <Link to="AddWork"><ButtonMenu>{t('addWork')}</ButtonMenu></Link>
                <Link to="CheckWork"><ButtonMenu>{t('myWork')}</ButtonMenu></Link>
                {/* <Link to="Messenger"><ButtonMenu>Сообщения</ButtonMenu></Link> */}
                <Link to="Favorite"><ButtonMenu>{t('favorite')}</ButtonMenu></Link>
                                    {/* <ButtonMenu>Подписки</ButtonMenu> */}
                <Link to="Message"><ButtonMenu>{t('messenger')}</ButtonMenu></Link>
                <Link to="SettingsMenu"><ButtonMenuBottom>{t('settings')}</ButtonMenuBottom></Link>
            </MenuSeller>
        </TextBlock>
    </Section>

    </>
  )
}

export default SellerCard

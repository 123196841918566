import React, { useState } from 'react'
import { Card } from './Card';
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import axios from "../../utils/axios";
import { useCallback, useEffect } from 'react';

const FullCard = (

) => {

    
  // // const [data, setData] = React.useState()
  // // const [loading, isLoading] = React.useState()
  // // const {id} = useParams();
  
  // // React.useEffect(() => {
  // //   axios.get(`/market/${id}`).then((res) => {
  // //     setData(res.data)
  // //   }).catch((err) =>{
  // //     console.warn(err)
  // //     alert('Ошибка при получении карточки')
  // //   })
  // // }, [])



  // // console.log(id)


  

  //   const [card, setCard] = useState(null)
  //   const params = useParams()

  //   const fetchCard = useCallback(async () => {
  //       const { data } = await axios.get(`/market/${params.id}`)
  //       setCard(data)

  //   }, [params.id])

  //   useEffect(() => {
  //       fetchCard()
  //   }, [fetchCard])

  //   console.log(card)

  //   if (!card) {
  //     return (
  //       <div>Загрузка</div>
  //     )
  //   }

    return(
        <>
        <Card 
        // name={card.name} 
        // userName={card.author.username}
        // userSurname={card.author.surname}
        // description={card.description}  
        // size={card.size}
        // price={card.price}
        // currency={card.currency}
        // hashtags={card.hashtags}
        // shopname={card.author.shopname}
        // gallery={[
        //   `/api/${card.imgUrl}`,
        // ]}
        // likeCount={card.likeCount}
        // _id={card.author._id}
        // category={card.cathegory}
        />
        </>
    )
}

export default FullCard